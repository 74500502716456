exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animal-welfare-js": () => import("./../../../src/pages/animal-welfare.js" /* webpackChunkName: "component---src-pages-animal-welfare-js" */),
  "component---src-pages-certifications-js": () => import("./../../../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-de-arbeitsplaetze-js": () => import("./../../../src/pages/de/arbeitsplaetze.js" /* webpackChunkName: "component---src-pages-de-arbeitsplaetze-js" */),
  "component---src-pages-de-cookies-policy-js": () => import("./../../../src/pages/de/cookies-policy.js" /* webpackChunkName: "component---src-pages-de-cookies-policy-js" */),
  "component---src-pages-de-emissionsarmer-transport-js": () => import("./../../../src/pages/de/emissionsarmer-transport.js" /* webpackChunkName: "component---src-pages-de-emissionsarmer-transport-js" */),
  "component---src-pages-de-hemat-des-atlantischen-lachses-js": () => import("./../../../src/pages/de/hemat-des-atlantischen-lachses.js" /* webpackChunkName: "component---src-pages-de-hemat-des-atlantischen-lachses-js" */),
  "component---src-pages-de-im-offenen-meer-aufgezogen-js": () => import("./../../../src/pages/de/im-offenen-meer-aufgezogen.js" /* webpackChunkName: "component---src-pages-de-im-offenen-meer-aufgezogen-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-kontakt-js": () => import("./../../../src/pages/de/kontakt.js" /* webpackChunkName: "component---src-pages-de-kontakt-js" */),
  "component---src-pages-de-privacy-policy-js": () => import("./../../../src/pages/de/privacy-policy.js" /* webpackChunkName: "component---src-pages-de-privacy-policy-js" */),
  "component---src-pages-de-produkte-js": () => import("./../../../src/pages/de/produkte.js" /* webpackChunkName: "component---src-pages-de-produkte-js" */),
  "component---src-pages-de-qualitat-js": () => import("./../../../src/pages/de/qualitat.js" /* webpackChunkName: "component---src-pages-de-qualitat-js" */),
  "component---src-pages-de-tierwohl-js": () => import("./../../../src/pages/de/tierwohl.js" /* webpackChunkName: "component---src-pages-de-tierwohl-js" */),
  "component---src-pages-de-uber-hiddenfjord-js": () => import("./../../../src/pages/de/uber-hiddenfjord.js" /* webpackChunkName: "component---src-pages-de-uber-hiddenfjord-js" */),
  "component---src-pages-de-wirklich-nachaltige-aufzucht-js": () => import("./../../../src/pages/de/wirklich-nachaltige-aufzucht.js" /* webpackChunkName: "component---src-pages-de-wirklich-nachaltige-aufzucht-js" */),
  "component---src-pages-de-zertifizierungen-js": () => import("./../../../src/pages/de/zertifizierungen.js" /* webpackChunkName: "component---src-pages-de-zertifizierungen-js" */),
  "component---src-pages-es-bienestar-de-animales-js": () => import("./../../../src/pages/es/bienestar-de-animales.js" /* webpackChunkName: "component---src-pages-es-bienestar-de-animales-js" */),
  "component---src-pages-es-certificaciones-js": () => import("./../../../src/pages/es/certificaciones.js" /* webpackChunkName: "component---src-pages-es-certificaciones-js" */),
  "component---src-pages-es-contact-js": () => import("./../../../src/pages/es/contact.js" /* webpackChunkName: "component---src-pages-es-contact-js" */),
  "component---src-pages-es-cookies-policy-js": () => import("./../../../src/pages/es/cookies-policy.js" /* webpackChunkName: "component---src-pages-es-cookies-policy-js" */),
  "component---src-pages-es-criado-en-la-naturaleza-js": () => import("./../../../src/pages/es/criado-en-la-naturaleza.js" /* webpackChunkName: "component---src-pages-es-criado-en-la-naturaleza-js" */),
  "component---src-pages-es-eleccion-de-calidad-js": () => import("./../../../src/pages/es/eleccion-de-calidad.js" /* webpackChunkName: "component---src-pages-es-eleccion-de-calidad-js" */),
  "component---src-pages-es-hogar-del-salmon-del-atlantico-js": () => import("./../../../src/pages/es/hogar-del-salmon-del-atlantico.js" /* webpackChunkName: "component---src-pages-es-hogar-del-salmon-del-atlantico-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-nuestros-productos-js": () => import("./../../../src/pages/es/nuestros-productos.js" /* webpackChunkName: "component---src-pages-es-nuestros-productos-js" */),
  "component---src-pages-es-piscicultura-verdaderamente-sostenible-js": () => import("./../../../src/pages/es/piscicultura-verdaderamente-sostenible.js" /* webpackChunkName: "component---src-pages-es-piscicultura-verdaderamente-sostenible-js" */),
  "component---src-pages-es-privacy-policy-js": () => import("./../../../src/pages/es/privacy-policy.js" /* webpackChunkName: "component---src-pages-es-privacy-policy-js" */),
  "component---src-pages-es-trabajos-js": () => import("./../../../src/pages/es/trabajos.js" /* webpackChunkName: "component---src-pages-es-trabajos-js" */),
  "component---src-pages-es-transporte-de-bajas-emisiones-js": () => import("./../../../src/pages/es/transporte-de-bajas-emisiones.js" /* webpackChunkName: "component---src-pages-es-transporte-de-bajas-emisiones-js" */),
  "component---src-pages-es-una-emepresa-familiar-js": () => import("./../../../src/pages/es/una-emepresa-familiar.js" /* webpackChunkName: "component---src-pages-es-una-emepresa-familiar-js" */),
  "component---src-pages-fr-bien-etre-animal-js": () => import("./../../../src/pages/fr/bien-etre-animal.js" /* webpackChunkName: "component---src-pages-fr-bien-etre-animal-js" */),
  "component---src-pages-fr-certificats-js": () => import("./../../../src/pages/fr/certificats.js" /* webpackChunkName: "component---src-pages-fr-certificats-js" */),
  "component---src-pages-fr-choix-de-qualite-js": () => import("./../../../src/pages/fr/choix-de-qualite.js" /* webpackChunkName: "component---src-pages-fr-choix-de-qualite-js" */),
  "component---src-pages-fr-contact-js": () => import("./../../../src/pages/fr/contact.js" /* webpackChunkName: "component---src-pages-fr-contact-js" */),
  "component---src-pages-fr-cookies-policy-js": () => import("./../../../src/pages/fr/cookies-policy.js" /* webpackChunkName: "component---src-pages-fr-cookies-policy-js" */),
  "component---src-pages-fr-elevage-veritablement-issu-du-developpement-durable-js": () => import("./../../../src/pages/fr/elevage-veritablement-issu-du-developpement-durable.js" /* webpackChunkName: "component---src-pages-fr-elevage-veritablement-issu-du-developpement-durable-js" */),
  "component---src-pages-fr-eleve-en-pleine-mer-js": () => import("./../../../src/pages/fr/eleve-en-pleine-mer.js" /* webpackChunkName: "component---src-pages-fr-eleve-en-pleine-mer-js" */),
  "component---src-pages-fr-emplois-js": () => import("./../../../src/pages/fr/emplois.js" /* webpackChunkName: "component---src-pages-fr-emplois-js" */),
  "component---src-pages-fr-foyer-du-saumon-de-l-atlantique-js": () => import("./../../../src/pages/fr/foyer-du-saumon-de-l-atlantique.js" /* webpackChunkName: "component---src-pages-fr-foyer-du-saumon-de-l-atlantique-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-nos-produits-js": () => import("./../../../src/pages/fr/nos-produits.js" /* webpackChunkName: "component---src-pages-fr-nos-produits-js" */),
  "component---src-pages-fr-notre-compagnie-js": () => import("./../../../src/pages/fr/notre-compagnie.js" /* webpackChunkName: "component---src-pages-fr-notre-compagnie-js" */),
  "component---src-pages-fr-privacy-policy-js": () => import("./../../../src/pages/fr/privacy-policy.js" /* webpackChunkName: "component---src-pages-fr-privacy-policy-js" */),
  "component---src-pages-fr-transport-avec-de-faibles-emissions-carbone-js": () => import("./../../../src/pages/fr/transport-avec-de-faibles-emissions-carbone.js" /* webpackChunkName: "component---src-pages-fr-transport-avec-de-faibles-emissions-carbone-js" */),
  "component---src-pages-home-of-the-atlantic-salmon-js": () => import("./../../../src/pages/home-of-the-atlantic-salmon.js" /* webpackChunkName: "component---src-pages-home-of-the-atlantic-salmon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-allevato-nel-suo-ambiente-naturale-js": () => import("./../../../src/pages/it/allevato-nel-suo-ambiente-naturale.js" /* webpackChunkName: "component---src-pages-it-allevato-nel-suo-ambiente-naturale-js" */),
  "component---src-pages-it-basse-emissioni-di-spedizione-js": () => import("./../../../src/pages/it/basse-emissioni-di-spedizione.js" /* webpackChunkName: "component---src-pages-it-basse-emissioni-di-spedizione-js" */),
  "component---src-pages-it-certificazioni-di-qualita-js": () => import("./../../../src/pages/it/certificazioni-di-qualita.js" /* webpackChunkName: "component---src-pages-it-certificazioni-di-qualita-js" */),
  "component---src-pages-it-contatti-js": () => import("./../../../src/pages/it/contatti.js" /* webpackChunkName: "component---src-pages-it-contatti-js" */),
  "component---src-pages-it-cookies-policy-js": () => import("./../../../src/pages/it/cookies-policy.js" /* webpackChunkName: "component---src-pages-it-cookies-policy-js" */),
  "component---src-pages-it-i-nostri-prodotti-js": () => import("./../../../src/pages/it/i-nostri-prodotti.js" /* webpackChunkName: "component---src-pages-it-i-nostri-prodotti-js" */),
  "component---src-pages-it-il-benessere-degli-animali-js": () => import("./../../../src/pages/it/il-benessere-degli-animali.js" /* webpackChunkName: "component---src-pages-it-il-benessere-degli-animali-js" */),
  "component---src-pages-it-index-js": () => import("./../../../src/pages/it/index.js" /* webpackChunkName: "component---src-pages-it-index-js" */),
  "component---src-pages-it-la-casa-naturale-del-salmone-atlantico-js": () => import("./../../../src/pages/it/la-casa-naturale-del-salmone-atlantico.js" /* webpackChunkName: "component---src-pages-it-la-casa-naturale-del-salmone-atlantico-js" */),
  "component---src-pages-it-la-nostra-azienda-js": () => import("./../../../src/pages/it/la-nostra-azienda.js" /* webpackChunkName: "component---src-pages-it-la-nostra-azienda-js" */),
  "component---src-pages-it-la-qualita-come-scelta-js": () => import("./../../../src/pages/it/la-qualita-come-scelta.js" /* webpackChunkName: "component---src-pages-it-la-qualita-come-scelta-js" */),
  "component---src-pages-it-lavori-js": () => import("./../../../src/pages/it/lavori.js" /* webpackChunkName: "component---src-pages-it-lavori-js" */),
  "component---src-pages-it-privacy-policy-js": () => import("./../../../src/pages/it/privacy-policy.js" /* webpackChunkName: "component---src-pages-it-privacy-policy-js" */),
  "component---src-pages-it-un-autentico-allveamento-sosteinibile-js": () => import("./../../../src/pages/it/un-autentico-allveamento-sosteinibile.js" /* webpackChunkName: "component---src-pages-it-un-autentico-allveamento-sosteinibile-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-low-emission-shipment-js": () => import("./../../../src/pages/low-emission-shipment.js" /* webpackChunkName: "component---src-pages-low-emission-shipment-js" */),
  "component---src-pages-media-and-press-materials-js": () => import("./../../../src/pages/media-and-press-materials.js" /* webpackChunkName: "component---src-pages-media-and-press-materials-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-company-js": () => import("./../../../src/pages/our-company.js" /* webpackChunkName: "component---src-pages-our-company-js" */),
  "component---src-pages-our-products-js": () => import("./../../../src/pages/our-products.js" /* webpackChunkName: "component---src-pages-our-products-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quality-choice-js": () => import("./../../../src/pages/quality-choice.js" /* webpackChunkName: "component---src-pages-quality-choice-js" */),
  "component---src-pages-raised-in-the-wild-js": () => import("./../../../src/pages/raised-in-the-wild.js" /* webpackChunkName: "component---src-pages-raised-in-the-wild-js" */),
  "component---src-pages-salmon-js": () => import("./../../../src/pages/salmon.js" /* webpackChunkName: "component---src-pages-salmon-js" */),
  "component---src-pages-truly-sustainable-farming-js": () => import("./../../../src/pages/truly-sustainable-farming.js" /* webpackChunkName: "component---src-pages-truly-sustainable-farming-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

